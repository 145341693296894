// React
import * as React from "react";

// MUI
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

// Classes
import ExternalURL from "../classes/ExternalURL";
import Page from "../classes/Page";
import MStyles from "../classes/MStyles";

/**
 * Glossary page
 * @param {*} props
 * @returns
 */
export default function Glossary(props) {
  const [page] = React.useState(Page.Glossary);
  const agriculture = React.useRef(null);
  const afforestation = React.useRef(null);
  const ageis = React.useRef(null);
  const anzsicref = React.useRef(null);
  const carboncapturestorage = React.useRef(null);
  const crf = React.useRef(null);
  const deforestation = React.useRef(null);
  const energy = React.useRef(null);
  const fugitive = React.useRef(null);
  const gwp = React.useRef(null);
  const industrial = React.useRef(null);
  const kpref = React.useRef(null);
  const LULUCF = React.useRef(null);
  const paris = React.useRef(null);
  const reforestation = React.useRef(null);
  const sectors = React.useRef(null);
  const unfccref = React.useRef(null);
  const waste = React.useRef(null);
  const A = React.useRef(null);
  const C = React.useRef(null);
  const D = React.useRef(null);
  const E = React.useRef(null);
  const F = React.useRef(null);
  const G = React.useRef(null);
  const I = React.useRef(null);
  const K = React.useRef(null);
  const L = React.useRef(null);
  const M = React.useRef(null);
  const N = React.useRef(null);
  const P = React.useRef(null);
  const R = React.useRef(null);
  const S = React.useRef(null);
  const T = React.useRef(null);
  const U = React.useRef(null);
  const W = React.useRef(null);

  React.useEffect(() => {
    if (props.page === page.page) {
      if (props.glossaryterm === "ANZSIC" && anzsicref)
        anzsicref.current.scrollIntoView();
      if (props.glossaryterm === "KP" && kpref) kpref.current.scrollIntoView();
      if (props.glossaryterm === "UNFCCC" && unfccref)
        unfccref.current.scrollIntoView();
    }
  }, [page.page, props.page, props.glossaryterm]);

  const scrollToReference = (ref) => {
    if (null === ref) return;
    if (null === ref.current) return;
    console.log(ref);
    ref.current.scrollIntoView();
  };

  // Do not render if the page name is different
  if (props.page !== page.page) return null;

  return (
    <div>
      <Typography variant="h1" sx={MStyles.h1_title} id="content">{page.title}</Typography>

      <nav aria-label="Glossary links">
        <Container sx={MStyles.glossary}>
          <Button onClick={() => scrollToReference(A)} variant="text" size="small" sx={{ minWidth: 0 }}>A</Button>
          <Button variant="text" size="small" sx={{ minWidth: 0 }} disabled>B</Button>
          <Button onClick={() => scrollToReference(C)} variant="text" size="small" sx={{ minWidth: 0 }}>C</Button>
          <Button onClick={() => scrollToReference(D)} variant="text" size="small" sx={{ minWidth: 0 }}>D</Button>
          <Button onClick={() => scrollToReference(E)} variant="text" size="small" sx={{ minWidth: 0 }}>E</Button>
          <Button onClick={() => scrollToReference(F)} variant="text" size="small" sx={{ minWidth: 0 }}>F</Button>
          <Button onClick={() => scrollToReference(G)} variant="text" size="small" sx={{ minWidth: 0 }}>G</Button>
          <Button disabled variant="text" size="small" sx={{ minWidth: 0 }}>H</Button>
          <Button onClick={() => scrollToReference(I)} variant="text" size="small" sx={{ minWidth: 0 }}>I</Button>
          <Button disabled variant="text" size="small" sx={{ minWidth: 0 }}>J</Button>
          <Button onClick={() => scrollToReference(K)} variant="text" size="small" sx={{ minWidth: 0 }}>K</Button>
          <Button onClick={() => scrollToReference(L)} variant="text" size="small" sx={{ minWidth: 0 }}>L</Button>
          <Button onClick={() => scrollToReference(M)} variant="text" size="small" sx={{ minWidth: 0 }}>M</Button>
          <Button onClick={() => scrollToReference(N)} variant="text" size="small" sx={{ minWidth: 0 }}>N</Button>
          <Button disabled variant="text" size="small" sx={{ minWidth: 0 }}>O</Button>
          <Button onClick={() => scrollToReference(P)} variant="text" size="small" sx={{ minWidth: 0 }}>P</Button>
          <Button disabled variant="text" size="small" sx={{ minWidth: 0 }}>Q</Button>
          <Button onClick={() => scrollToReference(R)} variant="text" size="small" sx={{ minWidth: 0 }}>R</Button>
          <Button onClick={() => scrollToReference(S)} variant="text" size="small" sx={{ minWidth: 0 }}>S</Button>
          <Button onClick={() => scrollToReference(T)} variant="text" size="small" sx={{ minWidth: 0 }}>T</Button>
          <Button onClick={() => scrollToReference(U)} variant="text" size="small" sx={{ minWidth: 0 }}>U</Button>
          <Button disabled variant="text" size="small" sx={{ minWidth: 0 }}>V</Button>
          <Button onClick={() => scrollToReference(W)} variant="text" size="small" sx={{ minWidth: 0 }}>W</Button>
          <Button disabled variant="text" size="small" sx={{ minWidth: 0 }}>X</Button>
          <Button disabled variant="text" size="small" sx={{ minWidth: 0 }}>Y</Button>
          <Button disabled variant="text" size="small" sx={{ minWidth: 0 }}>Z</Button>
        </Container>
      </nav>

      <article ref={A}>
        <Typography variant="h2" sx={MStyles.h2}>
          ABS
        </Typography>
        <Typography paragraph sx={MStyles.glossarylist}>
          The Australian Bureau of Statistics (ABS) is a major source of data
          for the agricultural sector and the national inventory by economic
          sector.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2}>
          Accounting Framework
        </Typography>
        <Typography paragraph sx={MStyles.glossarylist}>
          The National Greenhouse Accounts are compiled consistent with the{" "}
          <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(paris) }} >Paris Agreement</Link>
          ,{" "}
          <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(unfccref) }} >UNFCCC</Link>{" "}
          and{" "}
          <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(kpref) }} >Kyoto Protocol</Link>{" "}
          accounting provisions.
        </Typography>
        <Typography paragraph sx={MStyles.glossarylist}>
          The principal source of difference between the three accounting
          frameworks is the treatment of emissions sources and sinks from the
          land use, land use change and forestry sector and the global warming
          potentials used to convert non-carbon dioxide greenhouse gases into a
          carbon dioxide equivalent (CO<sub>2</sub>-e).
        </Typography>
        <Typography paragraph sx={MStyles.glossarylist}>
          UNFCCC provisions are underpinned by a comprehensive approach to
          emissions accounting and require the inclusion of all sources and
          sinks where there is adequate data while Kyoto provisions require a
          more limited set of sources and sinks from land use change and
          forestry activities (i.e{" "}
          <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(afforestation) }}>
            afforestation
          </Link>
          ,{" "}
          <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(reforestation) }}>
            reforestation
          </Link>{" "}
          and{" "}
          <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(deforestation) }}>
            deforestation
          </Link>
          ). The Paris Agreement framework will use the same comprehensive
          approach as the UNFCCC.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2}>
          Activity
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          A process that generates greenhouse gas emissions or uptake. In some
          sectors it refers to the level of energy consumption, production or
          manufacture for a given process or category or animal numbers.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2} ref={afforestation}>
          Afforestation
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          Afforestation is a subset of land converted to forest land and
          includes only those forests established since 1 January 1990 on land
          that was clear of forest on 31 December 1989. Forests under land
          converted to forest land may be established through planting events
          either for commercial timber or for other reasons, known as
          'environmental plantings', or by regeneration from natural seed
          sources on lands regulated for the protection of forests.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2} ref={ageis}>
          AGEIS
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The Australian Greenhouse Emissions Information System (AGEIS)
          centralises the Department's emissions estimation, emissions data
          management and reporting systems. AGEIS is being used to compile
          National and State and Territory inventories.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2} ref={agriculture}>
          Agriculture Sector
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The Inventory is divided into five <dfn>Intergovernmental Panel on Climate Change</dfn> (<abbr>IPCC</abbr>)  defined{" "}
          <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(sectors) }}>
            sectors
          </Link>
          . The Agriculture sector is <abbr>IPCC</abbr> Sector 3. Emissions in this sector
          are from livestock, manure management and crop residue, as well as
          emissions from rice cultivation, application of nitrogen to soils, and
          burning of agricultural residues.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2} ref={anzsicref}>
          ANZSIC
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The <dfn>Australian and New Zealand Standard Industrial Classification</dfn> (<abbr>ANZSIC</abbr>)
          is derived from international classifications (<dfn>International Standard Industrial Classifications</dfn> (<abbr>ISIC</abbr>)) and provides a framework for
          organising data about businesses - by enabling grouping of business units carrying out similar productive activities. The ANZSIC was
          developed by the Australian Bureau of Statistics (ABS) in collaboration with Statistics New Zealand.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2}>
          Activity Table
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          Activity tables present activity data (i.e. quantity of fuel consumed,
          animal numbers etc) that is used to derive emission estimates.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2}>
          Australia's National Greenhouse Accounts
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          Department of Climate Change, Energy, the Environment and Water publishes a suite of reports that, together, constitute the Australian National Greenhouse Accounts.
        </Typography>
        <Typography paragraph sx={MStyles.glossarylist}>
          The Accounts include:
        </Typography>
        <Box sx={MStyles.unorderedlist}>
          <ul>
            <li style={MStyles.unorderedlistelement}>
              the Quarterly Updates of the National Greenhouse Gas Inventory provides the latest emission estimates reported in line with rules adopted under the Paris Agreement;
            </li>
            <li style={MStyles.unorderedlistelement}>
              the State and Territory Greenhouse Gas Inventories provides emission estimates for each state and territory. It is a disaggregation of the emissions estimates in the National Inventory Report submitted to the <dfn>United Nations Framework Convention on Climate Change</dfn> (<abbr>UNFCCC</abbr>) each year;
            </li>
            <li style={MStyles.unorderedlistelement}>
              the National Inventory by Economic Sector provides national and state and territory emission estimates by economic sector rather than by <abbr title="Intergovernmental Panel on Climate Change">IPCC</abbr> sectors; and
            </li>
            <li style={MStyles.unorderedlistelement}>
              the National Inventory Report is prepared under the reporting provisions applicable to the Paris Agreement.
            </li>
          </ul>
        </Box>
      </article>

      <article ref={C}>
        <Typography variant="h2" sx={MStyles.h2} ref={carboncapturestorage}>
          Carbon Capture and Storage
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          Carbon dioxide from industrial and energy-related sources that is
          separated (captured), conditioned, compressed and transported to a
          geological storage location for long-term isolation from the
          atmosphere.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2}>
          Confidentiality
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          Data that is considered to be commercially sensitive is reported as
          "C" in the{" "}
          <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(crf) }}>
            common reporting format
          </Link>{" "}
          (CRF) tables. Confidential emissions are reported as an aggregated CO<sub>2</sub> equivalent value.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2} ref={crf}>
          Common Reporting Format (CRF) Table
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The common reporting format tables form part of the National Inventory
          Report. The CRF tables are a{" "}
          <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(unfccref) }} >
            <dfn>United Nations Framework Convention on Climate Change</dfn>
          </Link>{" "}(<abbr>UNFCCC</abbr>) reporting template for countries to report their greenhouse gas
          emissions by sector, gas, trends and recalculated data in an
          electronic format. For each inventory year the CRF tables are compiled
          for the current inventory year and re-compiled for each year back to
          1990.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2}>
          Consumption-based accounting
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The consumption based emissions inventory and projection accounts only
          for emissions generated, either domestically or overseas, in support
          of production of goods and services that are finally consumed in
          Australia. This accounting approach exclude emissions generated during
          the production of exports, but also includes overseas emissions
          generated during production of the products that are imported for
          domestic consumption.
        </Typography>
      </article>

      <article ref={D}>
        <Typography variant="h2" sx={MStyles.h2} ref={deforestation}>
          Deforestation
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          Deforestation under the Kyoto Protocol is a subset of forest conversion and includes only lands where there has been direct human-induced conversion of forest to alternative land uses since 1 January 1990.
        </Typography>
      </article>

      <article ref={E}>
        <Typography variant="h2" sx={MStyles.h2}>
          Emission Type
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The release of a particular gas to the atmosphere as a result of a
          certain activity. Emissions can be one of the following four types:
        </Typography>
        <Box sx={MStyles.unorderedlist}>
          <ul>
            <li style={MStyles.unorderedlistelement}>
              Generated - the gross result of a process or activity;
            </li>
            <li style={MStyles.unorderedlistelement}>
              Recovered - the capture of generated emissions for use in a
              secondary process, such as power generation;
            </li>
            <li style={MStyles.unorderedlistelement}>
              Sinks - a net gain in carbon stocks, such as those found in
              forests;
            </li>
            <li style={MStyles.unorderedlistelement}>
              Net emissions - the remaining gas released to the atmosphere after
              generation, recovery and sinks are taken into account.
            </li>
          </ul>
        </Box>
        <Typography paragraph sx={MStyles.glossarylist}>
          The most common data in the{" "}
          <Link sx={MStyles.linkstyle} onClick={() => scrollToReference(ageis)} >
            AGEIS
          </Link>{" "}
          are net estimates of emissions.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2}>
          Emission Factors
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The quantity of greenhouse gases emitted per unit of some specified
          activity.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2}>
          Emissions Projection
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          Emissions projections detail Australia's greenhouse emissions trends to 2040, analysing the factors driving emissions in each sector and estimating the effort needed to meet Australia's emissions reduction targets.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2} ref={energy}>
          Energy Sector
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The Inventory is divided into five IPCC defined{" "}
          <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(sectors) }}>
            sectors
          </Link>
          . The Energy sector is IPCC Sector 1. Emissions in the Energy sector
          include fuel combustion, fugitive emissions, and carbon dioxide
          transport and storage.
        </Typography>
        <Typography paragraph sx={MStyles.glossarylist}>
          Fuel combustion includes:
        </Typography>
        <Box sx={MStyles.unorderedlist}>
          <ul>
            <li style={MStyles.unorderedlistelement}>
              Electricity - Emissions from the combustion of fuels to generate
              electricity
            </li>
            <li style={MStyles.unorderedlistelement}>
              Stationary energy - Emissions from the combustion of fuels to
              generate steam, heat or pressure, other than for electricity
              generation and transport
            </li>
            <li style={MStyles.unorderedlistelement}>
              Transport - Emissions from the combustion of fuels for
              transportation within Australia
            </li>
          </ul>
        </Box>
        <Typography paragraph sx={MStyles.glossarylist}>
          <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(fugitive) }}>
            Fugitive
          </Link>{" "}
          emissions are emissions released during the extraction, processing and
          delivery of fossil fuels. Carbon Dioxide Transport and Storage
          emissions are from the transport, injection and storage of carbon
          dioxide associated with{" "}
          <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(carboncapturestorage) }}>
            carbon capture and storage
          </Link>{" "}
          activities.
        </Typography>
      </article>

      <article ref={F}>
        <Typography variant="h2" sx={MStyles.h2} ref={fugitive}>
          Fugitive Emissions
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          Fugitive emissions involve the release of non-combustion, greenhouse
          gases arising from the production and delivery of fossil fuels.
          Fugitive emissions from solid fuels arise from the production,
          transport and handling of coal, and emissions from decommissioned
          mines and coal mine waste gas flaring. Fugitive emissions from oil and
          gas extraction, production and transport involve venting, flaring,
          leakage, evaporation and storage losses. Fugitive emissions from
          carbon dioxide transport and storage arise from the transport,
          injection and storage of carbon dioxide associated with{" "}
          <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(carboncapturestorage) }}>
            carbon capture and storage
          </Link>{" "}
          activities.
        </Typography>
      </article>

      <article ref={G}>
        <Typography variant="h2" sx={MStyles.h2}>
          Gas
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          Rules adopted under the Paris Agreement require parties to report seven greenhouse gases:{" "}<dfn>carbon dioxide</dfn>{" "}(<abbr>CO<sub>2</sub></abbr>),{" "}
          <dfn>methane</dfn>{" "}(<abbr>CH<sub>4</sub></abbr>),{" "}
          <dfn>nitrous oxide</dfn>{" "}<abbr>(N<sub>2</sub>O</abbr>),{" "}
          <dfn>hydrofluorocarbons</dfn>{" "}(<abbr>HFCs</abbr>),{" "}
          <dfn>perfluorocarbons</dfn>{" "}(<abbr>PFCs</abbr>),{" "}
          <dfn>sulphur hexafluoride</dfn>{" "}(<abbr>SF<sub>6</sub></abbr>){" "}and {" "}
          <dfn>nitrogen trifluoride</dfn>{" "}(<abbr>NF<sub>3</sub></abbr>).
          Emissions from these gases are aggregated into <dfn>carbon dioxide equivalents</dfn>{" "}(<abbr>CO<sub>2</sub>-e</abbr>)
          using factors called{" "}<Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(gwp) }}><dfn>global warming potentials</dfn></Link>{" "}(<abbr>GWPs</abbr>).
          The default setting for the system is to report emissions of the seven main classes of
          gases aggregated into a single <abbr>CO<sub>2</sub>-e</abbr> estimate for each sector.
          Emissions of other, indirect gases, which cannot be aggregated because they do not have <abbr>GWPs</abbr> applied to them, are also reported individually.
          These gases include <dfn>nitrogen oxides</dfn> (<abbr>NOx</abbr>), <dfn>carbon monoxide</dfn> (<abbr>CO</abbr>),
          <dfn>non-methane volatile organic compounds</dfn> (<abbr>NMVOCs</abbr>) and <dfn>sulphur dioxide</dfn> (<abbr>SO<sub>2</sub></abbr>).
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2} ref={gwp}>
          <dfn>Global Warming Potential</dfn> (<abbr>GWP</abbr>)
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          Represents the relative warming effect of a unit mass of a greenhouse
          gas compared with the same mass of CO<sub>2</sub> over a specific period.
          Multiplying the actual amount of gas emitted by the GWP gives the <dfn><abbr>CO<sub>2</sub></abbr>-equivalent</dfn> (<abbr>CO<sub>2</sub>-e</abbr>) emissions. The GWPs used in the Paris
          Agreement inventory are from the IPCC Fifth Assessment Report,
          consistent with rules agreed under the Paris Agreement. The GWPs for
          inventories developed for reporting under the UNFCCC and Kyoto
          Protocol are from the IPCC Fourth Assessment Report.
        </Typography>
      </article>

      <article ref={I}>
        <Typography variant="h2" sx={MStyles.h2} ref={industrial}>
          Industrial Processes and Product Use (IPPU) Sector
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The Inventory is divided into five IPCC defined{" "}
          <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(sectors) }}>
            sectors
          </Link>
          . The Industrial Processes and Product Use (IPPU) sector is IPCC
          sector 2. Emissions from the IPPU sector are from non-energy related
          industrial production and processes. It includes emissions from
          hydrofluorocarbons (HFCs), which are used in refrigerants and air
          conditioning.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2}>
          Intergovernmental Panel on Climate Change (IPCC)
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The IPCC produces the 2006 IPCC Guidelines for National Greenhouse Gas
          Inventories (2006 Guidelines). The 2006 Guidelines were produced at
          the invitation of the United Nations Framework Convention on Climate
          Change (UNFCCC) to update the Revised 1996 Guidelines and associated
          good practice guidance which provide internationally agreed
          methodologies intended for use by countries to estimate greenhouse gas
          inventories to report to the UNFCCC. The IPCC also produces the 2013
          Supplement to the 2006 IPCC Guidelines for National Greenhouse Gas
          Inventories: Wetlands and the 2019 Refinement to the 2006 IPCC
          Guidelines for National Greenhouse Gas Inventories which Australia
          uses to inform the voluntary reporting of additional emissions sources
          to improve the completeness of its inventory.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2}>
          Inventory Year
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The year in which emissions occur. The Australian inventory is sourced
          from Australian fiscal years data as key data sources obtained from
          national statistical agencies are published on this basis. For example
          the year 2020 refers to the Australian fiscal year from 1 July 2019 to
          30 June 2020.
        </Typography>
      </article>

      <article ref={K}>
        <Typography variant="h2" sx={MStyles.h2}>
          Key Source Category
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          A key source category has a significant influence on a country's total
          inventory of direct greenhouse gases in terms of absolute level of
          emissions, the trend in emissions, or both. Tier 1 key source analysis
          identifies sources that contribute to 95% of the total emissions or
          95% of the trend of the inventory in absolute terms.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2} ref={kpref}>
          Kyoto Protocol
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The Kyoto Protocol was developed through the <dfn>United Nations Framework Convention on Climate Change</dfn> (<abbr>UNFCCC</abbr>) negotiating
          process and is an international treaty designed to limit global greenhouse gas emissions. The Protocol sets binding greenhouse gas
          emissions targets for <abbr>UNFCCC</abbr> developed country parties that ratified the agreement.
        </Typography>
        <Typography paragraph sx={MStyles.glossarylist}>
          The Kyoto framework has unique accounting provisions for the
          estimation of a country's greenhouse gas emissions, providing for the
          inclusion of specific sources and sinks from the land use, land use
          change and forestry sector.
        </Typography>
        <Typography paragraph sx={MStyles.glossarylist}>
          2020 was the final year of the Kyoto Protocol Second Commitment Period.
        </Typography>
      </article>

      <article ref={L}>
        <Typography variant="h2" sx={MStyles.h2} ref={LULUCF}>
          Land Use, Land Use Change and Forestry (LULUCF) Sector
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The Inventory is divided into five IPCC defined{" "}
          <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(sectors) }}>
            sectors
          </Link>
          . The Land Use, Land Use Change and Forestry (LULUCF) sector is IPCC
          sector 4. Emissions and sequestration in this sector are from
          activities occurring on forest lands, forests converted to other land
          uses, grasslands, croplands, wetlands and settlements.
        </Typography>
      </article>

      <article ref={M}>
        <Typography variant="h2" sx={MStyles.h2} >
          Methodology
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The methodology used for the estimation of Australia's national
          greenhouse gas inventory is documented in the{" "}
          <Link sx={MStyles.linkstyle} rel="noreferrer" href={ExternalURL.NIR.url} alt={ExternalURL.NIR.alt} >
            {ExternalURL.NIR.text}
          </Link>
          .
        </Typography>
      </article>

      <article ref={N}>
        <Typography variant="h2" sx={MStyles.h2}>
          Nationally Determined Contributions (NDCs)
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          Under the Paris Agreement, Australia must submit emissions reduction
          commitments known as Nationally Determined Contributions (NDCs). These
          include our national target.
        </Typography>
      </article>

      <article ref={P}>
        <Typography variant="h2" sx={MStyles.h2} ref={paris}>
          Paris Agreement
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The Paris Agreement is a legally binding international treaty that
          came into force in 2016 and aims to strengthen the global response to
          the threat of climate change by:
        </Typography>
        <Box sx={MStyles.unorderedlist}>
          <ul>
            <li style={MStyles.unorderedlistelement}>
              holding the increase in the global average temperature to well
              below 2&deg;C above pre-industrial levels
            </li>
            <li style={MStyles.unorderedlistelement}>
              pursuing efforts to limit temperature increase to 1.5&deg;
              C.
            </li>
          </ul>
        </Box>
        <Typography paragraph sx={MStyles.glossarylist}>
          Under the Paris Agreement, all parties must communicate <dfn>nationally determined contributions</dfn> (<abbr>NDCs</abbr>) and report progress against these NDCs.
          The Paris framework for emissions reporting includes an update to global warming potentials (<abbr>GWP-100</abbr>) to those published in the <dfn><abbr title="Intergovernmental Panel on Climate Change">IPCC</abbr></dfn> Fifth Assessment Report.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2}>
          Paris Agreement Inventory Memo Items
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          Emissions reported as Memo Items under the Paris Agreement Inventory
          are:
        </Typography>
        <Box sx={MStyles.unorderedlist}>
          <ul>
            <li style={MStyles.unorderedlistelement}>
              carbon dioxide emissions from biomass;
            </li>
            <li style={MStyles.unorderedlistelement}>
              indirect nitrogen oxide (<abbr>N<sub>2</sub>O</abbr>) emissions from sources other than those reported in the agriculture and LULUCF sectors; and
            </li>
            <li style={MStyles.unorderedlistelement}>
              emissions from international maritime and aviation bunkers.
            </li>
          </ul>
        </Box>
        <Typography paragraph sx={MStyles.glossarylist}>
          Memo Items are not included in a country&apos;s total national
          emission estimates.
        </Typography>
      </article>

      <article ref={R}>
        <Typography variant="h2" sx={MStyles.h2} ref={reforestation}>
          Reforestation
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          Reforestation is a subset of land converted to forest land
          and includes only those forests established since 1 January 1990 on
          land that was clear of forest on 31 December 1989. Forests under land
          converted to forest land may be established through planting events
          either for commercial timber or for other reasons, known as
          'environmental plantings', or by regeneration from natural seed
          sources on lands regulated for the protection of forests.
        </Typography>
      </article>

      <article ref={S}>
        <Typography variant="h2" sx={MStyles.h2}>
          Scope 1 Emissions
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          Direct greenhouse gas emissions.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2}>
          Scope 2 Emissions
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          Indirect greenhouse gas emissions from the generation of purchased
          electricity. Purchased electricity is defined as electricity that is
          purchased or otherwise brought into the organisational boundary of the
          entity. Emissions from electricity generation consumed within the
          electricity, gas and water sector are included for completeness
          although this electricity use includes own use of generators and does
          not necessarily meet the definition of scope 2 emissions. The sum of
          scope 2 emissions is equal to the direct (scope 1) emissions from
          electricity generation (<dfn>Intergovernmental Panel on Climate Change</dfn> <abbr>IPCC</abbr> Source Category 1.A.1.a).
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2} ref={sectors}>
          Sector
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The Inventory is divided into 5 <dfn>Intergovernmental Panel on Climate Change</dfn> (<abbr>IPCC</abbr>) defined sectors based on
          particular emissions processes:
        </Typography>
        <Box sx={MStyles.unorderedlist}>
          <ul>
            <li style={MStyles.unorderedlistelement}>
              <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(energy) }}>
                Energy (IPCC sector 1),
              </Link>
            </li>
            <li style={MStyles.unorderedlistelement}>
              <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(industrial) }}>
                Industrial Processes and Product Use (sector 2),
              </Link>
            </li>
            <li style={MStyles.unorderedlistelement}>
              <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(agriculture) }}>
                Agriculture (sector 3),
              </Link>
            </li>
            <li style={MStyles.unorderedlistelement}>
              <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(LULUCF) }}>
                Land Use, Land User Change and Forestry (sector 4),
              </Link>{" "}
              and{" "}
            </li>
            <li style={MStyles.unorderedlistelement}>
              <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(waste) }}>
                Waste (sector 5).
              </Link>
            </li>
          </ul>
        </Box>
        <Typography paragraph sx={MStyles.glossarylist}>
          For any particular industry, emissions may be generated through more
          than one emissions process. For example, for the aluminium industry,
          process emissions from the production of aluminium are accounted for
          in the Industrial Processes sector while the fuel combusted for energy
          in the production process is accounted for in the Energy sector.
          Similarly, for the agricultural industry, emissions from fuel
          combustion are reported under Energy, while emissions from
          agricultural processes (for example enteric fermentation) are recorded
          under Agriculture.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2}>
          Source
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          Any process or activity that releases a greenhouse gas, an aerosol or
          a precursor of a greenhouse gas into the atmosphere.
        </Typography>
      </article>

      <article>
        <Typography variant="h2" sx={MStyles.h2}>
          Submission Year
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The submission year is the year in which the Department submits its national inventory report in accordance with <abbr title="United Nations Framework Convention on Climate Change">UNFCCC</abbr> requirements, it is always two years after the latest inventory year covered by the report. The submission comprises a complete time-series of inventory years from the base year (1990).
        </Typography>
      </article>

      <article ref={T}>
        <Typography variant="h2" sx={MStyles.h2}>
          Tier
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The <dfn>Intergovernmental Panel on Climate Change</dfn> (<abbr>IPCC</abbr>) methods for estimating emissions and removals are divided
          into 'Tiers' encompassing different levels of activity and technology
          detail. Tier 1 methods are generally straightforward (activity
          multiplied by default emissions factor) and require less data and
          expertise than the most complicated Tier 3 methods. Tier 2 and 3
          methods have higher levels of complexity and require more detailed
          country-specific information on things such as technology type or
          livestock characteristics. The concept of Tiers is also used to
          describe different levels of key source analysis, uncertainty
          analysis, and quality assurance and quality control activities.
        </Typography>
      </article>

      <article ref={U}>
        <Typography variant="h2" sx={MStyles.h2} ref={unfccref}>
          United Nations Framework Convention on Climate Change (UNFCCC)
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          An international treaty that commits signatory countries to stabilise
          anthropogenic greenhouse gas emissions to levels that would prevent
          dangerous interference with the climate system. The UNFCCC also
          requires signatories to develop and update national inventories of
          anthropogenic emissions of all greenhouse gases not otherwise
          controlled by the{" "}
          <Link
            sx={MStyles.linkstyle}
            rel="noreferrer"
            href={ExternalURL.MONTREAL.url}
            alt={ExternalURL.MONTREAL.alt}
          >
            Montreal Protocol
          </Link>
          .
        </Typography>
      </article>

      <article ref={W}>
        <Typography variant="h2" sx={MStyles.h2} ref={waste}>
          Waste Sector
        </Typography>

        <Typography paragraph sx={MStyles.glossarylist}>
          The Inventory is divided into five <dfn>Intergovernmental Panel on Climate Change</dfn> (<abbr>IPCC</abbr>) defined{" "}
          <Link sx={MStyles.linkstyle} onClick={() => { scrollToReference(sectors) }}>
            sectors
          </Link>
          . The waste sector is <abbr>IPCC</abbr> sector 5. Emissions in this sector are from
          the disposal of solid waste and wastewater.
        </Typography>
      </article>
    </div>
  );
}
