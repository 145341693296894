// React
import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Classes
import ArrayUtility from "../classes/ArrayUtility";
import Messages from "../classes/Messages";
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";
import TransformationUtility from "../classes/TransformationUtility";
import TreeUtility from "../classes/TreeUtility";

// Components
import MultipleSelectTree from "./MultipleSelectTree";
import SingleSelectGasTree from "./SingleSelectGasTree";
import SingleSelectTree from "./SingleSelectTree";
import Visualisation from "./Visualisation";

/**
 * Paris content page
 * @param {*} props
 * @returns
 */
export default function Paris(props) {

  // Reducer function for a filter
  function reducer(state, filter) {
    return {
      datatypedisabled: filter.datatypedisabled,
      datatypeselected: filter.datatypeselected,
      fueldisabled: filter.fueldisabled,
      fuelselected: filter.fuelselected,
      gasselected: filter.gasselected,
      locationselected: filter.locationselected,
      sectorselected: filter.sectorselected,
      sectortree: filter.sectortree
    };
  }

  // Use reducer to minimize invocations of API
  // This should contain all variables that are invoked by API
  // It should be initialized with default values
  const [filter, dispatch] = React.useReducer(
    reducer,
    {
      datatypedisabled: true,
      datatypeselected: props.datatypeselected,
      fueldisabled: true,
      fuelselected: props.fuelnotavailableid,
      gasselected: props.gasdefaultar,
      locationselected: props.locationselected,
      sectorselected: props.sectorselected,
      sectortree: props.sectortree
    }
  )

  const [charttype, setChartType] = React.useState("StackedBar");
  const [displayline, setDisplayLine] = React.useState(true);
  const [displaybar, setDisplayBar] = React.useState(false);
  const [displaystackedbar, setDisplayStackedBar] = React.useState(true);

  const [hasdata, setHasData] = React.useState(false);
  const [partialdata, setPartialData] = React.useState(false);

  // Chart elements. Data transformed for chart display
  const [chartdata, setChartData] = React.useState(null);
  const [lines, setLines] = React.useState(null);

  // Table elements. Data transformed for table display
  const [tabledata, setTableData] = React.useState(null);
  const [headers, setHeaders] = React.useState(null);

  // Dialog filter elements
  const [datatypedialog, setDataTypeDialog] = React.useState(false);
  const [datatypekey, setDataTypeKey] = React.useState("dt" + Date.now());

  const [fueldialog, setFuelDialog] = React.useState(false);
  const [fuelkey, setFuelKey] = React.useState("f" + Date.now());

  const [gasdialog, setGasDialog] = React.useState(false);
  const [gaskey, setGasKey] = React.useState("g" + Date.now());

  const [locationdialog, setLocationDialog] = React.useState(false);
  const [locationkey, setLocationKey] = React.useState("l" + Date.now());

  const [sectordialog, setSectorDialog] = React.useState(false);
  const [sectorkey, setSectorKey] = React.useState("s" + Date.now());

  // Loading flag
  const [loading, setLoading] = React.useState(false);

  // Display labels
  const [location, setLocation] = React.useState("");
  const [sector, setSector] = React.useState("");
  const [gas, setGas] = React.useState("");
  const [units, setUnits] = React.useState("");
  const [fuel, setFuel] = React.useState("");
  const [emissiontype, setEmissionType] = React.useState("");

  // Navigation of this page
  const page = Page.Paris;
  const digits = 4;
  //#region Effects. Effects are run in order they are specified

  /**
   * Reset to default if pages changes.
   */
  React.useEffect(() => {
    if (props.page === Page.SearchResults.page) return;
    if (props.page !== page.page) {
      dispatch({
        datatypedisabled: true,
        datatypeselected: props.datatypeselected,
        fueldisabled: true,
        fuelselected: props.fuelnotavailableid,
        gasselected: props.gasdefaultar,
        locationselected: props.locationselected,
        sectorselected: props.sectorselected,
        sectortree: props.sectortree
      });
    }
  }, [
    page,
    props.page,
    props.datatypeselected,
    props.fuelnotavailableid,
    props.gasdefaultar,
    props.locationselected,
    props.sectorselected,
    props.sectortree,
  ]);

  /**
   * Set search terms. When entering filter should be set to defaults
   */
  React.useEffect(() => {
    if (props.page === Page.SearchResults.page) {

      let dt = props.datatypenotavailableid;
      let f = props.fuelnotavailableid;
      let g = props.gasdefaultar;
      let s = props.sectorselected;

      if (-1 !== props.searchfuelid) {
        f = props.searchfuelid.toString();
        s = props.defaultsectorforfuelsearch.toString();
      }

      if (-1 !== props.searchgasrawid) { g = props.searchgasrawid; }
      if (-1 !== props.searchgasar5id) { g = props.searchgasar5id; }

      if (-1 !== props.searchsectorid) { s = props.searchsectorid; }

      const dtdisabled = !TreeUtility.allWithinParents(props.sectortree, [s], props.topdatatypesectors);
      const fdisabled = !TreeUtility.allWithinParents(props.sectortree, [s], props.topfuelsectors);
      if (!dtdisabled) { dt = props.datatypeselected }
      if (!fdisabled && -1 === props.searchfuelid) { f = props.fuelselected; }

      dispatch({
        datatypedisabled: dtdisabled,
        datatypeselected: dt,
        fueldisabled: fdisabled,
        fuelselected: f,
        gasselected: g,
        locationselected: props.locationselected,
        sectorselected: s,
        sectortree: props.sectortree
      });
    }
  }, [
    props.datatypenotavailableid,
    props.datatypeselected,
    props.defaultsectorforfuelsearch,
    props.fuelnotavailableid,
    props.fuelselected,
    props.gasdefaultar,
    props.locationselected,
    props.page,
    props.searchfuelid,
    props.searchgasar5id,
    props.searchgasrawid,
    props.searchsectorid,
    props.sectorselected,
    props.sectortree,
    props.topdatatypesectors,
    props.topfuelsectors
  ]);

  /**
   * Get time series if any of the selections changed
   */
  React.useEffect(() => {
    /**
     * Transform data into components used by visualization.
     * This follows successful data load so it is included in this effect.
     * @param {*} apid
     */
    function transformdata(apid) {
      let _chartdata = [];
      let _lines = [];
      let _headers = [];
      let _tabledata = [];

      // Populate data for XAxis key and name are reserved names
      // Populae data for year table column
      TransformationUtility.prepareTimeSeries(
        props.firstyear,
        props.lastyear,
        _headers,
        _chartdata,
        _tabledata
      );

      const _generationspread = TransformationUtility.calculateGenerationSpread(
        filter.sectortree,
        filter.sectorselected
      );

      const maxvalue = TransformationUtility.getMaxValue(
        apid,
        filter.sectorselected,
        _tabledata
      );

      const scale = TransformationUtility.getScale(maxvalue);

      const hd = TransformationUtility.populateTimeSeries(
        apid,
        filter.sectorselected,
        filter.sectortree,
        _chartdata,
        _lines,
        _tabledata,
        _headers,
        digits,
        scale.factor
      );

      _lines = TransformationUtility.getLinesWithData(_lines, _chartdata);

      const gas = TransformationUtility.calculateGasDisplayName(
        props.gastreeraw,
        props.gastreear,
        filter.gasselected
      );

      const loc = TransformationUtility.calculateTitle(
        false,
        props.locationtree,
        filter.locationselected
      );
      const sectors1 = TransformationUtility.calculateSelectionList(
        filter.sectortree,
        filter.sectorselected
      );
      const e = TransformationUtility.calculateTitle(
        filter.datatypedisabled,
        props.datatypetree,
        filter.datatypeselected
      );

      const f = TransformationUtility.calculateTitle(
        filter.fueldisabled,
        props.fueltree,
        filter.fuelselected
      );

      const g = _generationspread ? "Line" : "StackedBar";


      for (let h = 1; h < _headers.length; h++) {
        _headers[h].label += " (" + scale.units + ")";
      }
      setLines(_lines);
      setChartData(_chartdata);
      setHeaders(_headers);
      setTableData(_tabledata);
      setChartType(g);
      setDisplayLine(true);
      setDisplayBar(false);
      setDisplayStackedBar(!_generationspread);
      setLocation(loc);
      setSector(sectors1);
      setGas(gas);
      setHasData(hd.hasdata);
      setPartialData(hd.partialdata);
      setFuel(f);
      setEmissionType(e);
      setUnits(scale.units);
    }

    let base = "/api/OutputTimeSeries?";
    if (props.page !== page.page) return;
    if (!filter) return;
    if (!filter.locationselected) return;
    if (!filter.sectorselected) return;
    if (!filter.gasselected) return;
    if (!props.fuelnotavailableid) return;
    if (!props.datatypenotavailableid) return;

    setLoading(true);

    const sectorstring = ArrayUtility.convertArrayToStringIDs(filter.sectorselected);
    // Documentation see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    // Note that URLSearchParams will URLEncode strings
    fetch(
      base +
      new URLSearchParams({
        urlencodedsectorlist: sectorstring,
        locationid: filter.locationselected,
        fuelid: filter.fuelselected,
        gasid: filter.gasselected,
        datatypeid: filter.datatypeselected,
      }),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json()) // Obtain promise
      .then((data) => {
        // Obtain data from promise
        if (data) {
          if (data.error) {
            console.log("Error: " + data.error);
            setLines(null);
            setChartData(null);
            setHeaders(null);
            setTableData(null);
          } else {
            const d = data.data;
            for (let i = 0; i < d.length; i++) {
              d[i]["key"] = i;
            }
            transformdata(d);
          }
        } else {
          setLines(null);
          setChartData(null);
          setHeaders(null);
          setTableData(null);
        }
      })
      .catch((data) => {
        console.log("Error", data);
        setLines(null);
        setChartData(null);
        setHeaders(null);
        setTableData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    filter,
    page.page,
    props.datatypenotavailableid,
    props.datatypetree,
    props.firstyear,
    props.fuelnotavailableid,
    props.fueltree,
    props.gastreear,
    props.gastreeraw,
    props.lastyear,
    props.locationtree,
    props.page
  ]);

  /**
   * Get sector hierarchy if location has changed.
   * Please note that this is not an effect.
   * It is called directly by change location handler.
   */
  function loadSectorHierarchy(location) {
    setLoading(true);
    const base = "/api/SectorHierarchy?";
    // Documentation see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    fetch(
      base +
      new URLSearchParams({
        LocationID: location,
        top: "UNFCCC",
      }),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json()) // Obtain promise
      .then((data) => {
        // Obtain data from promise
        if (data) {
          dispatch({
            datatypedisabled: true,
            datatypeselected: props.datatypenotavailableid,
            fueldisabled: true,
            fuelselected: props.fuelnotavailableid,
            gasselected: props.gasdefaultar,
            locationselected: location,
            sectorselected: [data.Default],
            sectortree: data.Nodes
          });
        } else {
          console.log("Error: No data returned");
        }
      })
      .catch((data) => {
        console.log("Error: ", data);
      });
    // After this we need to load data so do not change loading status
  }

  //#endregion

  //#region Data Type
  /**
   * Cancel selection of Data Type
   */
  function cancelselectDataType() {
    setDataTypeDialog(false);
  }

  /**
   * Open data type selection dialog
   */
  function openDataTypeDialog() {
    setDataTypeKey("dt" + Date.now());
    setDataTypeDialog(true);
  }

  /**
   * Save selection of data type. Update Data
   */
  function saveselectDataType(value) {
    setDataTypeDialog(false);
    const f = filter;
    dispatch({
      datatypedisabled: f.datatypedisabled,
      datatypeselected: value,
      fueldisabled: f.fueldisabled,
      fuelselected: f.fuelselected,
      gasselected: f.gasselected,
      locationselected: f.locationselected,
      sectorselected: f.sectorselected,
      sectortree: f.sectortree
    });
  }
  //#endregion

  //#region Fuel

  /**
   * Cancel selection of Fuel
   */
  function cancelselectFuel() {
    setFuelDialog(false);
  }

  /**
   * Open fuel selection dialog
   */
  function openFuelDialog() {
    setFuelKey("f" + Date.now());
    setFuelDialog(true);
  }

  /**
   * Save selection of fuel. Updata data
   * @param {*} value selected key
   */
  function saveselectFuel(value) {
    setFuelDialog(false);
    const f = filter;
    dispatch({
      datatypedisabled: f.datatypedisabled,
      datatypeselected: f.datatypeselected,
      fueldisabled: f.fueldisabled,
      fuelselected: value,
      gasselected: f.gasselected,
      locationselected: f.locationselected,
      sectorselected: f.sectorselected,
      sectortree: f.sectortree
    });
  }

  //#endregion

  //#region Gas

  /**
   * Cancel selection of Gas
   */
  function cancelselectGas() {
    setGasDialog(false);
  }

  /**
   * Open Gas dialog
   */
  function openGasDialog() {
    setGasKey("g" + Date.now());
    setGasDialog(true);
  }

  /**
   * Save selection of Gas
   */
  function saveselectGas(value) {
    setGasDialog(false);
    const f = filter;
    dispatch({
      datatypedisabled: f.datatypedisabled,
      datatypeselected: f.datatypeselected,
      fueldisabled: f.fueldisabled,
      fuelselected: f.fuelselected,
      gasselected: value,
      locationselected: f.locationselected,
      sectorselected: f.sectorselected,
      sectortree: f.sectortree
    });

  }

  //#endregion

  //#region Location

  /**
   * Cancel selection of Location
   */
  function cancelselectLocation() {
    setLocationDialog(false);
  }

  /**
   * Open location selection dialog
   */
  function openLocationDialog() {
    setLocationKey("l" + Date.now());
    setLocationDialog(true);
  }

  /**
   * Save selection of location.
   * Sector hierarchies are slightly different for different locations so need to update
   * both hierarchy and data after change of location
   */
  function saveselectLocation(value) {
    setLocationDialog(false);
    loadSectorHierarchy(value);
  }

  //#endregion

  //#region Sector
  /**
   * Cancel selection
   */
  function cancelselectSector() {
    setSectorDialog(false);
  }

  /**
   * Open sector selection dialog
   */
  function openSectorDialog() {
    setSectorKey("s" + Date.now());
    setSectorDialog(true);
  }

  /**
   * Save selected sectors
   */
  function saveselectSector(value) {
    const arr = [];
    let fdisabled = true;
    let dtdisabled = true;
    if (Array.isArray(value)) {
      for (const s of value) {
        const n = TreeUtility.findinTree(filter.sectortree, null, s);
        if (n) {
          arr.push(n.name);
        }
      }
      fdisabled = !TreeUtility.allWithinParents(
        filter.sectortree,
        value,
        props.topfuelsectors
      );

      dtdisabled = !TreeUtility.allWithinParents(
        filter.sectortree,
        value,
        props.topdatatypesectors
      );
    } else {
      const n = TreeUtility.findinTree(filter.sectortree, null, value);
      if (n) {
        arr.push(n.name);
      }
      fdisabled = !TreeUtility.allWithinParents(
        filter.sectortree,
        [value],
        props.topfuelsectors
      );

      dtdisabled = !TreeUtility.allWithinParents(
        filter.sectortree,
        [value],
        props.topdatatypesectors
      );
    }

    setSectorDialog(false);
       
    let fuel = props.fuelnotavailableid;    
    if (fdisabled) {
      fuel = props.fuelnotavailableid;
    }
    else {
      if (filter.fueldisabled)
      {
        fuel = props.fuelselected;
      }
      else
      {
        fuel = filter.fuelselected;
      }
    }

    let datatype = props.datatypenotavailableid;
    if (dtdisabled)
    {
      datatype = props.datatypenotavailableid;
    }
    else {
      if (filter.datatypedisabled)
      {
        datatype = props.datatypeselected;
      }
      else {
        datatype = filter.datatypeselected;
      }
    }

    const f = filter;
    dispatch({
      datatypedisabled: dtdisabled,
      datatypeselected: datatype,
      fueldisabled: fdisabled,
      fuelselected: fuel,
      gasselected: props.gasdefaultar,
      locationselected: f.locationselected,
      sectorselected: value,
      sectortree: f.sectortree
    });
  }

  //#endregion

  if (props.page !== page.page) return null;

  if (loading || !props.sectortree)
    return (
      <Box sx={MStyles.progressboxstyle} display={"flex"}>
        <CircularProgress></CircularProgress>
      </Box>
    );
  // Data has been populated
  return (
    <div>
      <Typography variant="h1" sx={MStyles.h1_title} id="content">{page.title}</Typography>
      <Typography paragraph sx={MStyles.body}>
        View emission estimates for the inventory used to track Australia&apos;s
        progress towards its Paris Agreement targets.
      </Typography>
      <Typography paragraph sx={MStyles.body}>
        The chart shows total emissions since 1990.
      </Typography>
      <Typography paragraph sx={MStyles.body}>
        Selecting a point on the chart will display the emissions for that year.
      </Typography>
      <Typography variant="h2" sx={MStyles.h2}>
        Filter data using the buttons provided
      </Typography>
      <Box sx={MStyles.dimensionboxstyle}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2 }}
        >
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openLocationDialog();
            }}
            disabled={loading}
          >
            Select location
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openSectorDialog();
            }}
            disabled={loading}
          >
            Select sector
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={filter.fueldisabled ? MStyles.hidestyle : MStyles.buttondimstyle}
            onClick={() => {
              openFuelDialog();
            }}
            disabled={loading}
          >
            Select fuel
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openGasDialog();
            }}
            disabled={loading}
          >
            Select gas
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={filter.datatypedisabled ? MStyles.hidestyle : MStyles.buttondimstyle}
            onClick={() => {
              openDataTypeDialog();
            }}
            disabled={loading}
          >
            Select emission type
          </Button>
        </Stack>
      </Box>

      <Visualisation
        digits={digits}
        isprojections={false}
        isdate={true}
        hasdata={hasdata}
        partialdata={partialdata}
        confidential={false}
        ariatitle="Paris Agreement inventory emissions"
        bar={displaybar}
        data={chartdata}
        defaulttype={charttype}
        displaylocation={true}
        displaysector={false}
        fileprefix={page.title}
        headers={headers}
        line={displayline}
        lines={lines}
        stackedbar={displaystackedbar}
        tabledata={tabledata}
        title="Paris Agreement inventory emissions"
        units={units}
        XAxisTitle="Year"
        YAxisTitle="Emissions"
        location={location}
        sector={sector}
        gas={gas}
        fuel={fuel}
        emissiontype={emissiontype}
      />

      <SingleSelectTree
        key={datatypekey}
        open={datatypedialog}
        tree={props.datatypetree}
        selected={filter.datatypeselected}
        title="Select emission type"
        onCancel={() => {
          cancelselectDataType();
        }}
        onSave={(selection) => {
          saveselectDataType(selection);
        }}
      />

      <SingleSelectTree
        key={fuelkey}
        open={fueldialog}
        tree={props.fueltree}
        selected={filter.fuelselected}
        title="Select fuel"
        onCancel={() => {
          cancelselectFuel();
        }}
        onSave={(selection) => {
          saveselectFuel(selection);
        }}
      />

      <SingleSelectGasTree
        key={gaskey}
        isAR5={true}
        isProjection={false}
        open={gasdialog}
        defaultar={props.gasdefaultar}
        treear={props.gastreear}
        defaultraw={props.gasdefaultraw}
        treeraw={props.gastreeraw}
        selected={filter.gasselected}
        title="Select gas"
        onCancel={() => {
          cancelselectGas();
        }}
        onSave={(selection) => {
          saveselectGas(selection);
        }}
      />

      <SingleSelectTree
        key={locationkey}
        open={locationdialog}
        tree={props.locationtree}
        selected={filter.locationselected}
        title="Select Location"
        onCancel={() => {
          cancelselectLocation();
        }}
        onSave={(selection) => {
          saveselectLocation(selection);
        }}
      />

      <MultipleSelectTree
        key={sectorkey}
        messages={Messages.SECTOR_PARIS_INFO}
        open={sectordialog}
        tree={filter.sectortree}
        selected={filter.sectorselected}
        title="Select sectors"
        topsector="Total UNFCCC"
        onCancel={() => {
          cancelselectSector();
        }}
        onSave={(selection) => {
          saveselectSector(selection);
        }}
      />
      <Box display={hasdata ? "block" : "none"} sx={MStyles.datafootnotebox}>
        <Typography paragraph sx={MStyles.body}>
          Estimates are based on the classification system used to report Australia&apos;s 
          greenhouse gas emission inventory under the United Nations Framework Convention on Climate Change (UNFCCC).  
          These estimates are compiled using the global warming potentials from the Fifth Assessment Report (AR5) 
          of the Intergovernmental Panel on Climate Change (IPCC).
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          Emission estimates for subsectors in certain locations are not
          available due to confidentiality constraints. As a consequence, some
          industries will present as greater than the sum of their available
          parts.
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          Bar charts are not available for queries with different levels of the sectoral hierarchy selected. For example, if the user selects 1 Energy and 1.A Fuel Combustion sectors the data will be available as a line chart or table only, as 1.A Fuel Combustion is a subsector of 1 Energy.
        </Typography>
      </Box>
    </div>
  );
}
